/* In your external stylesheet or inline styles */
h3 span::before,
h3 span::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 90px; /* Adjust the width as needed */
  border-bottom: 1px solid #878787; /* You can change the color and thickness as needed */
  pointer-events: none; /* Ensure the lines don't interfere with clicks */
}

h3 span::before {
  left: -100px; /* Adjust the distance from the "or" text */
}

h3 span::after {
  right: -100px; /* Adjust the distance from the "or" text */
}

/* h1 {
  font-family: 'Libre Baskerville', serif;
} */

h2 li input {
  font-family: 'Open Sans', sans-serif;
}

