/* In your external stylesheet or inline styles */
h1 span::before,
h1 span::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 100px; /* Adjust the width as needed */
  border-bottom: 1px solid #878787; /* You can change the color and thickness as needed */
  pointer-events: none; /* Ensure the lines don't interfere with clicks */
}

h1 span::before {
  left: -115px; /* Adjust the distance from the "or" text */
}

h1 span::after {
  right: -115px; /* Adjust the distance from the "or" text */
}
